<template>
  <div>
    <NewPost
      :newPostDialogOn="editDialog"
      :postType="post.type"
      :post="post"
      :edit="enableEdit"
      @close="editDialog = false"
      @update="emitUpdate"
    />

    <v-card
      class="mx-auto"
      :outlined="!$vuetify.theme.dark"
      style="border-radius: 18px;"
      @click="openPost"
    >
      <v-container>
        <v-list-item>
          <v-list-item-avatar
            size="50"
            :color="`grey ${ $vuetify.theme.dark ? 'darken-1' : 'lighten-3'}`"
          >
            <v-img
              v-if="post.sender.avatar"
              :src="getResourceUrl(post.sender.avatar)"
            />
            <v-icon v-else>
              {{ mdiAccountCircle }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <div class="overline">
              {{ post.sender.name }}
              <span class="caption grey--text">
                - {{ createdAtDistanceToNow }}
              </span>
            </div>
          </v-list-item-content>

          <v-list-item-action>
            <v-speed-dial
              v-if="post.sender.profile === profile"
              v-model="fab"
              open-on-hover
              direction="bottom"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator>
                <v-btn
                  v-model="fab"
                  small
                  icon
                >
                  <v-icon v-if="fab">{{ mdiClose }}</v-icon>
                  <v-icon v-else>{{ mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-tooltip
                v-if="post.type === 'thread'"
                left
                :disabled="!settings.tooltipsOn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    small
                    :color="fabColor"
                    v-on="on"
                    @click="editDialog = true"
                  >
                    <v-icon
                      small
                      style="transform: rotate(-45deg)"
                    >
                      {{ mdiFountainPenTip }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>edit</span>
              </v-tooltip>

              <v-tooltip
                left
                :disabled="!settings.tooltipsOn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    small
                    :color="fabColor"
                    v-on="on"
                    @click="deactivate"
                  >
                    <v-icon small>{{ mdiDelete }}</v-icon>
                  </v-btn>
                </template>
                <span>remove</span>
              </v-tooltip>
            </v-speed-dial>
          </v-list-item-action>
        </v-list-item>

        <v-card-text>
          <div class="my-2"></div>

          <SocialPost
            v-if="post.type === 'thread'"
            :postStr="JSON.stringify(post)"
            :dense="dense"
          />
        </v-card-text>

        <v-card-actions>
          <v-row
            align="center"
            class="px-4"
          >
            <v-btn
              icon
              @click="like"
            >
              <v-icon :color="likeContent ? 'red darken-4' : ''">
                {{ likeContent ? mdiHeart : mdiHeartOutline }}
              </v-icon>
            </v-btn>

            <v-btn
              icon
              @click="save"
            >
              <v-icon :color="saveContent ? 'primary' : ''">
                {{ saveContent ? mdiBookmark : mdiBookmarkOutline }}
              </v-icon>
            </v-btn>
           </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import { formatDate, latexOptions, getResourceUrl } from '@utils'
import { CommentContainer } from '@components/Comment'
import SocialPost from './SocialPost'
import NewPost from './NewPost'
import API from '@api'

import {
  mdiDotsVertical,
  mdiClose,
  mdiDelete,
  mdiHeart,
  mdiHeartOutline,
  mdiFountainPenTip,
  mdiBookmarkOutline,
  mdiBookmark,
  mdiAccountCircle
} from '@mdi/js'

export default {
  components: {
    Scrollbar,
    SocialPost,
    CommentContainer,
    NewPost
  },

  props: {
    objectId: {
      type: String,
      required: true
    },
    currentPost: {
      type: Boolean,
      default: false
    },
    post: {
      type: Object,
      required: true
    },
    community: {
      type: Object,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    dense: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    const [engagement] = await API().get(`engagement/${this.user.id}`, {
      params: {
        post: this.post.id,
        profile: this.profile
      }
    })
    
    if (engagement) {
      this.likeContent = engagement.likeContent
      this.saveContent = engagement.saveContent
    }
    
    this.setComments()
  },

  data() {
    return {
      mdiDotsVertical,
      mdiDelete,
      mdiClose,
      mdiHeart,
      mdiHeartOutline,
      mdiFountainPenTip,
      mdiBookmarkOutline,
      mdiBookmark,
      mdiAccountCircle,

      latexOptions,

      editDialog: false,

      saveContent: false,
      likeContent: false,

      comments: [],
      commentLimit: 2
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user',
      settings: 'settings',
      profile: 'profile/id',
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar',
      myBio: 'profile/bio',
      myUsername: 'profile/username',
      scrollTop: 'scrollTop'      
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.profileName,
        avatar: this.profileAvatar,
        bio: this.myBio,
        username: this.myUsername
      }
    },

    enableEdit() {
      return this.post.type === 'thread'
    },

    fabColor() {
      return this.$vuetify.theme.dark
        ? 'secondary'
        : 'primary'
    },

    createdAtDistanceToNow() {
      return formatDate(this.post.createdAt, this.$i18n.locale)
    },

    loadCommentsColor() {
      return this.$vuetify.theme.dark
        ? 'text--lighten-1'
        : 'text--darken-2'
    },

    commentListColor() {
      return this.$vuetify.theme.dark
        ? '#2E2E2E'
        : 'grey lighten-5'
    }
  },

  watch: {
    objectId() {
      this.setComments()
    }
  },

  methods: {
    ...mapMutations({
      SET_CURRENT_POST: 'currentPost'
    }),

    getResourceUrl,

    async openPost() {
      // TODO: review implementation
      if (this.post.chats) {
        // set post
        this.SET_CURRENT_POST(this.post)
        this.$router.push(`/rooms/conversation?id=${this.post.chats[0].id}&community=${this.community.id}`)
      }

      this.$emit('open')
    },

    async setComments() {
      this.comments = [] 
      
      // await API().get(`comments/${this.user.id}`, {
      //   params: {
      //     post: this.post.id,
      //     isPublic: true
      //   }
      // })
    },

    updateComment({ text, index }) {
      this.comments[index].paragraphs = [text]
    },

    emitUpdate(paragraphs) {
      this.$emit('update', { paragraphs, index: this.index })
    },

    async like() {
      await API().put(`engagement/${this.user.id}`, {
        likeContent: !this.likeContent
      }, {
        params: {
          query: {
            post: {
              $eq: this.post.id
            },
            profile: {
              $eq: this.profile
            }
          },
          options: {
            upsert: true
          }
        }
      })

      this.likeContent = !this.likeContent
    },

    async save() {
      await API().put(`engagement/${this.user.id}`, {
        saveContent: !this.saveContent
      }, {
        params: {
          query: {
            post: {
              $eq: this.post.id
            },
            profile: {
              $eq: this.profile
            }
          },
          options: {
            upsert: true
          }
        }
      })

      this.saveContent = !this.saveContent
    },

    async deactivate() {
      API().put(`post/${this.user.id}`, {
        isActive: false
      }, {
        params: {
          id: this.post._id
        }
      })

      this.$emit('deactivate')
    },

    async postComment({ paragraph, contentMentions, mentions}) {
      const comment = await API().post(`comment/${this.user.id}`, {
        sender: this.sender,
        contentMentions,
        mentions,
        post: this.post.id,
        paragraphs: [paragraph],
        isPublic: this.isPublic
      })

      this.comments.push(comment)
    }
  }
}
</script>