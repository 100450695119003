<template>
  <div>
    <v-fade-transition hide-on-leave>
      <v-col
        v-if="images.length === 1"
        cols="12"
      >
        <ImageBoxPost
          :src="images[0]"
          :imgIdx="0"
          :removeOn="removeOn"
          @removeImage="removeImage"
          @imgClick="$emit('imgClick')"
        />
      </v-col>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-container v-if="images.length === 2">
        <v-row justify="center">
          <v-col
            cols="6"
            class="pr-1 py-0"
          >
            <ImageBoxPost
              :src="images[0]"
              :imgIdx="0"
              :height="250"
              @imgClick="$emit('imgClick')"              
            />
          </v-col>
          <v-col
            cols="6"
            class="pl-0 py-0"
          >
            <ImageBoxPost
              :src="images[1]"
              :imgIdx="1"
              :height="250"
              :removalOn="true"
              @removeImage="removeImage"
              @imgClick="$emit('imgClick')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-container v-if="images.length >= 3">
        <v-row justify="center">
          <v-col
            cols="12"
            class="py-0"
          >
            <ImageBoxPost
              :src="images[0]"
              :imgIdx="0"
              :removalOn="true"
              @removeImage="removeImage"
              @imgClick="$emit('imgClick')"
            />
          </v-col>
        </v-row>

        <div class="my-1" />

        <v-row justify="center">
          <v-col
            cols="6"
            class="pt-0 pr-1"
          >
            <ImageBoxPost
              :src="images[1]"
              :imgIdx="1"
              :height="150"
              @imgClick="$emit('imgClick')"
            />
          </v-col>
          <v-col
            cols="6"
            class="pt-0 pl-0"
          >
            <ImageBoxPost
              :src="images[2]"
              :imgIdx="2"
              :height="150"
              :overlay="images.length > 3"
              :overlayMsg="overlayMsg"
              @imgClick="$emit('imgClick')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </div>
</template>

<script>
import { ImageBoxPost } from '@components/Image'

export default {
  components: {
    ImageBoxPost
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    removeOn: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    overlayMsg() {
      const amount = this.images.length  - 3 
      return `+${amount}`
    }
  },

  methods: {
    removeImage() {
      this.$emit('removeImages')
    }
  }
}
</script>