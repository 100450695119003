<template>
  <v-container>
    <v-row
      justify="start"
      class="px-4"
    >
      <v-list-item-avatar
        size="40"
        color="grey"
      >
        <v-img
          v-if="comment.sender"
          :src="getResourceUrl(comment.sender.avatar)"
        />
      </v-list-item-avatar>

      <v-list-item-content v-if="comment.sender">
        <div class="overline">
          {{ comment.sender.name }}   
          <span class="caption grey--text">
            - {{ createdAt }}
          </span>
        </div>
      </v-list-item-content>

      <v-list-item-action>
        <v-fade-transition hide-on-leave>
          <v-row v-if="!edit">
            <v-tooltip
              v-if="comment.sender.profile === myProfile"
              :disabled="!settings.tooltipsOn"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  icon
                  @click="edit = true"
                >
                  <v-icon>
                    {{ mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>editar</span>
            </v-tooltip>
            
            <v-btn
              icon
              @click="$emit('reply', comment)"
            >
              <v-icon>
                {{ mdiReplyOutline }}
              </v-icon>
            </v-btn>
            
            <v-btn
              icon
              @click="like"
            >
              <v-icon :color="likeContent ? 'red darken-4' : ''">
                {{ likeContent ? mdiHeart : mdiHeartOutline }}
              </v-icon>
            </v-btn>
          </v-row>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <v-row v-if="edit">
            <v-btn
              icon
              small
              outlined
              class="mx-2"
              color="success"
              @click="syncEdit = true"
            >
              <v-icon>{{ mdiCheck }}</v-icon>
            </v-btn>

            <v-btn
              icon
              outlined
              small
              color="danger"
              @click="edit = false"
            >
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-row>
        </v-fade-transition>
      </v-list-item-action>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <markdown-it-vue
          v-for="(content, i) in commentContent"
          :key="`content_${i}`"
          class="md-body"
          :content="content"
          :options="options"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  formatDate,
  truncateStr,
  splitLatex,
  replaceLatexDelimiters,
  getResourceUrl
} from '@utils'
import MarkdownItVue from 'markdown-it-vue/src/index.js'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import API from '@api'

import {
  mdiDotsHorizontal,
  mdiClose,
  mdiCheck,
  mdiDelete,
  mdiHeart,
  mdiHeartOutline,
  mdiReplyOutline,
  mdiPencilOutline
} from '@mdi/js'

export default {
  components: {
    MarkdownItVue
  },

  props: {
    commentStr: {
      type: String,
      default: ``
    }
  },
  
  data: () => ({
    mdiDotsHorizontal,
    mdiDelete,
    mdiCheck,
    mdiClose,
    mdiHeart,
    mdiHeartOutline,
    mdiReplyOutline,
    mdiPencilOutline,

    options: {
      markdownIt: {
        linkify: true
      },
      katex: {
        throwOnError: false,
        errorColor: '#cc0000'
      },
      linkAttributes: {
        attrs: {
          target: '_blank',
          rel: 'noopener'
        }
      }
    },
    edit: false,
    likeContent: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      settings: 'settings',
      scrollTop: 'scrollTop',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar'
    }),

    comment() {
      return JSON.parse(this.commentStr)
    },

    commentContent() {
      return splitLatex(replaceLatexDelimiters(this.comment.content.blocks.map(({data}) => data.text).join('<br>')))
    },

    createdAt() {
      return formatDate(this.comment.createdAt, this.$i18n.locale)
    }
  },

  methods: {
    getResourceUrl,

    async updateComment(text) {
      await API().put(`comment/${this.user.id}`, {
        paragraphs: [text]
      }, {
        params: {
          id: this.comment.id
        }
      })

      this.$emit('updateComment', { text, index: this.index })
    },

    truncate(str = '', num = 200) {
      return truncateStr(str, num)
    },

    formatComment([paragraph]) {
      return paragraph.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    async like() {
      await API().put(`engagement/${this.user.id}`, {
        likeContent: !this.likeContent
      }, {
        params: {
          query: {
            comment: {
              $eq: this.comment.id
            },
            profile: {
              $eq: this.myProfile
            }
          },
          options: {
            upsert: true
          }
        }
      })

      this.likeContent = !this.likeContent
    },

    async setEngagement() {
      const [engagement] = await API().get(`engagement/${this.user.id}`, {
        params: {
          comment: this.comment.id,
          profile: this.myProfile
        }
      })

      if (engagement) {
        this.likeContent = engagement.likeContent
      }
    }
  }
}
</script>