<template>
  <div>
    <v-dialog
      v-model="emojiPickerLightOn"
      hide-overlay
      max-width="0"
      @click:outside="emojiPickerOn = false"
    >
      <v-card
        rounded="lg"
        elevation="10"
        :style="`position: absolute; max-width: 350px; left: ${emojiPickerLeft}px; top: ${emojiPickerTop}px;`"
      >
        <emoji-picker-light @click="emojiClick" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="emojiPickerDarkOn"
      hide-overlay
      max-width="0"
      @click:outside="emojiPickerOn = false"
    >
      <v-card
        rounded="lg"
        elevation="20"
        :style="`position: absolute; max-width: 350px; left: ${emojiPickerLeft}px; top: ${emojiPickerTop}px;`"
      >
        <emoji-picker-dark @click="emojiClick" />
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-fade-transition"
    >
      <Scrollbar style="height: 100vh;">
        <v-card
          rounded="0"
          style="overflow: hidden;"
        >
          <v-row justify="center">
            <v-col
              cols="9"
              class="py-0 pr-0"
            >
              <v-hover v-slot:default="{ hover }">
                <div style="position: relative;">
                  <v-fade-transition>
                    <v-row
                      v-if="hover"
                      justify="center"
                      align="center"
                      class="ml-1 mr-1 pt-1"
                      style="position: absolute; width: 100%; z-index: 10;"
                    >
                      <v-btn
                        fab
                        x-small
                        class="mx-2 my-2"
                        color="primary"
                        @click.prevent="closeDialog"
                      >
                        <v-icon>{{ mdiClose }}</v-icon>
                      </v-btn>

                      <v-spacer />

                      <v-btn
                        fab
                        icon
                        x-small
                        class="mx-2 my-2"
                        color="white"
                        :disabled="post.mediaContent.images.length === 0"
                        @click="displayMedia = 'image'"
                      >
                        <v-icon>
                          {{ mdiImageOutline }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        fab
                        icon
                        x-small
                        class="mx-2 my-2"
                        color="white"
                        :disabled="post.mediaContent.videos.length === 0"
                        @click="displayMedia = 'video'"
                      >
                        <v-icon>
                          {{ mdiMotionPlayOutline }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        fab
                        icon
                        x-small
                        class="mx-2 my-2"
                        color="white"
                        @click.prevent="contain = !contain"
                      >
                        <v-icon>{{ contain ? mdiFullscreen : mdiFullscreenExit }}</v-icon>
                      </v-btn>
                    </v-row>
                  </v-fade-transition>

                  <v-fade-transition hide-on-leave>
                    <div v-if="displayMedia === 'image'">
                      <v-carousel
                        v-model="mediaIndex"
                        show-arrows-on-hover
                        height="100vh"
                      >
                        <v-carousel-item
                          v-for="(image, i) in post.mediaContent.images"
                          :key="`image_${i}`"
                        >
                          <v-sheet color="grey darken-4">
                            <v-img
                              height="100vh"
                              :contain="contain"
                              :src="image.url"
                            />
                          </v-sheet>
                        </v-carousel-item>
                      </v-carousel>
                    </div>
                  </v-fade-transition>

                  <v-fade-transition hide-on-leave>
                    <div v-if="displayMedia === 'video'">
                      <v-carousel
                        v-model="mediaIndex"
                        show-arrows-on-hover
                        hide-delimiters
                        height="100vh"
                      >
                        <v-carousel-item
                          v-for="(video, i) in post.mediaContent.videos"
                          :key="`video_${i}`"
                        >
                          <v-sheet color="grey darken-4">
                            <YouTubePlayer
                              v-if="video.isYoutube"
                              :videoId="video.id"
                              :url="video.url"
                              :thumbnail="video.thumbnail"
                              :isPreview="false"
                              :syncTheaterMode="true"
                              :mediaViewer="true"
                              :syncMediaViewer="syncMediaViewer"
                              @theaterMode="toggleTheater"
                            />
                            <VideoPlayer
                              v-else
                              :videoId="video.id"
                              :url="video.url"
                              :thumbnail="video.thumbnail"
                              :isPreview="false"
                              :syncTheaterMode="true"
                              :mediaViewer="true"
                              :syncMediaViewer="syncMediaViewer"
                              @theaterMode="toggleTheater"
                            />
                          </v-sheet>
                        </v-carousel-item>
                      </v-carousel>
                    </div>
                  </v-fade-transition>
                </div>
              </v-hover>
            </v-col>

            <v-col
              cols="3"
              class="py-0 pl-0"
            >
              <v-card
                :class="$vuetify.theme.dark ? 'viewer-dark' : 'viewer-light'"
                elevation="0"
                rounded="0"
                height="100%"
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-avatar
                      :size="$vuetify.breakpoint.xl ? 45 : 40"
                      color="grey"
                    >
                      <v-img :src="mediaSender.avatar" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div>                    
                        <span :class="$vuetify.breakpoint.xl ? 'overline' : ''">
                          {{ truncateStr(mediaSender.name, 15) }}
                          <span class="caption grey--text">
                            - {{ createdAtDistanceToNow }}
                          </span>
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <v-divider />

                <v-card-text>
                  <v-btn
                    icon
                    @click="commentOn = !commentOn"
                  >
                    <v-icon>
                      {{ commentOn ? mdiTextBoxOutline : mdiCommentTextOutline }}
                    </v-icon>
                  </v-btn>

                  <span class="overline">
                    abrir {{ commentOn ? 'annotations' : 'comments' }}
                  </span>       
                </v-card-text>

                <v-divider inset />              

                <v-card-actions>
                  <v-container class="py-1">
                    <v-fade-transition hide-on-leave>
                      <v-row v-if="commentOn">
                        <v-col
                          cols="1"
                          :class="$vuetify.breakpoint.xl ? '' : 'px-2'"
                        >
                          <v-avatar :size="avatarSize">
                            <v-img
                              v-if="profileAvatar"
                              :src="profileAvatar"
                            >
                              <template v-slot:placeholder>
                                <v-skeleton-loader
                                  class="mx-auto"
                                  height="40"
                                  width="40"
                                  type="image"
                                />
                              </template>             
                            </v-img>
                            <v-btn
                              v-else
                              icon
                              color="white"
                            >
                              <v-icon
                                dark
                                size="30"
                              >
                                {{ mdiAccount }}
                              </v-icon>
                            </v-btn>
                          </v-avatar>
                        </v-col>

                        <v-col cols="11" class="pr-0">

                          <span ref="comment" />

                          <v-textarea
                            v-model="newComment"
                            class="px-5"
                            auto-grow
                            outlined
                            rows="1"
                            dense
                            hide-details
                            filled
                            label="Comentário..."
                            :background-color="commentColor"
                            :append-icon="mdiEmoticonExcitedOutline"
                            @click:append="setEmojiOn"
                            @keydown="postComment"
                          />
                        </v-col>
                      </v-row>
                    </v-fade-transition>

                    <v-fade-transition hide-on-leave>
                      <div v-if="!commentOn">
                        <v-card class="mx-6" outlined>
                          <v-btn
                            icon
                          >
                            <v-icon>
                              {{ mdiTextBoxOutline }}
                            </v-icon>
                          </v-btn>
                          <span class="overline">
                            annotate
                          </span>
                        </v-card>
                      </div>
                    </v-fade-transition>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </Scrollbar>
    </v-dialog>
  </div>
</template>

<script>
import { BACKGROUND_DARK_COLOR } from '@utils/constants';
import { formatDate, truncateStr } from '@utils';
import { VideoPlayer, YouTubePlayer } from '@components/Video';
import { Scrollbar } from '@components/App';
import { mapGetters } from 'vuex';

import {
  mdiClose,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiChevronRight,
  mdiChevronLeft,
  mdiEmoticonExcitedOutline,
  mdiAccount,
  mdiImageOutline,
  mdiMotionPlayOutline,
  mdiTextBoxOutline,
  mdiCommentTextOutline
} from '@mdi/js'

export default {
  components: {
    Scrollbar,
    VideoPlayer,
    YouTubePlayer
  },

  props: {
    dialogOn: {
      type: Boolean,
      default: false
    },
    post: {
      type: Object,
      default() {
        return {}
      }
    },
    selectedMedia: {
      type: String,
      default: 'image'
    }
  },

  created() {
    this.displayMedia = this.selectedMedia
  },

  data() {
    return {
      mdiClose,
      mdiFullscreen,
      mdiFullscreenExit,
      mdiChevronRight,
      mdiChevronLeft,
      mdiEmoticonExcitedOutline,
      mdiAccount,
      mdiImageOutline,
      mdiMotionPlayOutline,
      mdiTextBoxOutline,
      mdiCommentTextOutline,

      BACKGROUND_DARK_COLOR,

      syncMediaViewer: false,
      
      newComment: '',
      comments: [],
      commentLimit: 2,
      
      displayMedia: 'image',
      mediaIndex: 0,

      dialog: false,
      contain: true,

      emojiPickerTop: 10,
      emojiPickerLeft: 10,
      emojiPickerOn: false,

      commentOn: true
    }
  },

  computed: {
    ...mapGetters({
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar'
    }),

    currentMediaObj() {

      const defaultObj = { sender: {}, createdAt: '' }

      if (!this.post.mediaContent) {
        return defaultObj
      }

      const { images, videos } = this.post.mediaContent

      if (images.length > 0 && this.displayMedia === 'image') {
        
        return images[this.mediaIndex]
        
      } else if (videos.length > 0) {
        
        return videos[this.mediaIndex]
      
      } else {
      
        return defaultObj
      
      }

    },

    mediaSender() {
      return this.currentMediaObj.sender
    },

    menuBtnColor() {
      return this.displayMedia === 'video' ? 'white' : ''
    },

    emojiPickerLightOn() {
      return this.emojiPickerOn && !this.$vuetify.theme.dark
    },

    emojiPickerDarkOn() {
      return this.emojiPickerOn && this.$vuetify.theme.dark
    },

    createdAtDistanceToNow() {
      return formatDate(this.currentMediaObj.createdAt, this.$i18n.locale)
    },

    commentColor() {
      return this.$vuetify.theme.dark
        ? ''
        : 'grey lighten-5'
    },

    avatarSize() {
      return this.profileAvatar ? '40px' : ''
    }
  },

  watch: {
    selectedMedia(media) {
      this.mediaIndex = 0
      this.displayMedia = media
    },

    dialogOn() {
      this.dialog = this.dialogOn
    }
  },

  methods: {
    truncateStr,

    postComment(e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        this.comments.push(this.newComment)
        this.newComment = ''
      }
    },

    setEmojiOn() {
      const { y, x } = this.$refs.comment.getBoundingClientRect()
      this.emojiPickerTop = y > window.innerHeight / 2 ? y - 427 : y + 50
      const leftReducer = this.$vuetify.breakpoint.xl ? -20 : 100
      this.emojiPickerLeft = x - leftReducer
      this.emojiPickerOn = true
    },

    emojiClick(e) {
      const { parentElement } = e.target
      if (parentElement.classList.contains('emoji-mart-emoji')) {
        this.emojiPickerOn = false
        this.$root.$emitter.emit('SET_EMOJI')
        this.syncEmoji = true
        setTimeout(() => {
          this.syncEmoji = false
        })
      }
    },

    closeDialog() {
      this.dialog = false
      this.syncMediaViewer = !this.syncMediaViewer
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.viewer-dark {
 border-left: thin solid rgba(255, 255, 255, 0.12);
}
.viewer-light {
 border-left: thin solid rgba(0, 0, 0, 0.12);
}
</style>