<template>
  <div>    
    <PostCard
      :objectId="currentPost.id"
      :post="currentPost"
      :community="community"
    />
    
    <div class="my-4"></div>
    
    <CommentBox
      :post="currentPost"
      @comment="addComment"
    />

    <div class="my-4"></div>

    <div
      v-for="(comment, i) in comments"
      :key="`comment_${i}`"
      :class="{
        'px-4 mb-4': true,
        'dark--thread': $vuetify.theme.dark,
        'light--thread': !$vuetify.theme.dark
      }"
      style="border-radius: 12px;"
    >
      <Comment :commentStr="JSON.stringify(comment)" />
      <div v-if="comment.comments && comment.comments.length > 0">
        <div class="divider-text text-caption">
          {{ new Date().toDateString() }}
        </div>
        <Comment
          v-for="(reply, i) in comment.comments"
          :key="`replies_${i}`"
          :commentStr="JSON.stringify(reply)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PostCard, CommentBox, Comment } from '@components/Post'
import API from '@api'

export default {
  components: {
    PostCard,
    CommentBox,
    Comment
  },

  props: {
    currentPost: {
      type: Object,
      default: {}
    },
    community: {
      type: Object,
      default: {}
    }
  },

  async created() {
    if (this.currentPost.id) {
      this.comments = await API().get(`comments/${this.user.id}`, {
        params: {
          query: {
            post: {
              $eq: this.currentPost.id
            }
          }
        }
      })
    }
  },

  data: () => ({
    comments: []
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    addComment(comment) {
      this.comments.unshift(comment)
    }
  }
}
</script>

<style scoped>
  .divider-text {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .divider-text:before,
  .divider-text:after {
    content: '';
    flex: 1;
    border-bottom: 0.1px solid #afafaf9d;
  }

  .divider-text:before {
    margin-right: .5em;
  }

  .divider-text:after {
    margin-left: .5em;
  }
.dark--thread {
  background-color: #20202057
}
.light--thread {
  background-color: #ffffffed
}
</style>