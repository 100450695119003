<template>
  <v-card
    elevation="0"
    :class="{
      'mx-auto hs-rounded-xl': true,
      'editor-dark': $vuetify.theme.dark
    }"
  >
    <v-container>
      <v-card-text>
        <div
          :id="`editorjs_${EDITOR_ID}`"
          spellcheck="false"
          class="comment-box hidden--class"
          style="z-index: 2; padding: 10px 10px 20px 20px;"
          ref="postEditor"
          @click="focus"
        ></div>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-btn
          elevation="0"
          text
          class="px-3"
          :color="$vuetify.theme.dark ? 'tertiary' : 'primary'"
          :style="`border-radius: 12px; background-color: ${ $vuetify.theme.dark ? 'rgba(255, 191, 16, 0.23)' : 'rgba(74, 20, 140, 0.1)'};`"
        >
          ai generate
          <v-icon right>
            {{ mdiShimmer }}
          </v-icon>
        </v-btn>

        <v-btn
          elevation="0"
          text
          class="px-3"
          :style="`border-radius: 12px; background-color: ${ $vuetify.theme.dark ? 'rgba(107, 107, 107, 0.23)' : 'rgba(107, 107, 107, 0.1)'};`"
        >
          ai transform
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          elevation="0"
          color="grey lighten-4"
          class="grey--text text--darken-4"
          style="border-radius: 12px;"
          @click="submit"
        >
          comment
          <v-icon right>
            {{ mdiSend }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex';
import { mdiSend, mdiShimmer } from '@mdi/js';
import DefaultEditorJS from '@components/DefaultEditorJS'
import { v4 as uuidv4 } from 'uuid';
const EDITOR_ID = uuidv4()

export default {
  props: {
    post: {
      type: Object,
      default: () =>  ({})
    }
  },

  created() {
    this.setEditor()
  },
  
  data: () => ({
    mdiSend,
    mdiShimmer,

    EDITOR_ID: EDITOR_ID,
    editor: null
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      myName: 'profile/name',
      myUsername: 'profile/username',
      myAvatar: 'profile/avatar',
      myProfileId: 'profile/id',
    })
  },

  methods: {
    async submit() {
      const comment = await API().post(`comment/${this.user.id}`, {
        post: this.post.id,
        content: await this.editor.saver.save(),
        sender: {
          name: this.myName,
          username: this.myUsername,
          avatar: this.myAvatar,
          profile: this.myProfileId,
        }
      })
      this.editor.clear()
      this.$emit('comment', comment)
    },
    
    focus(evt) {
      if (evt.target.classList[0] != 'ce-paragraph')
        this.editor.caret.setToLastBlock('end')
    },

    async setEditor() {
      const editorPromise = DefaultEditorJS.newEditor(EDITOR_ID, 'What do you think?')
      await editorPromise.isReady;
      this.editor = editorPromise;
    }
  }
}
</script>

<style>
.comment-box {
  cursor: text;
  min-height: 100px;
}
.editor-dark .comment-box {
  border-radius: 18px;
  background-color: #171717d3;
}
.comment-box {
  border-radius: 18px;
  background-color: #e7e6e62b;
}
</style>