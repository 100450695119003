<template>
  <v-card
    class="mx-auto"
    :outlined="!$vuetify.theme.dark"
    style="border-radius: 18px;"
  >
    <v-container>
      <v-list-item>
        <v-list-item-avatar
          size="40"
          :color="`grey ${$vuetify.theme.dark ? 'darken-1' : 'lighten-3'}`"
        >
          <v-img :src="posts[0].sender.avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <div class="overline">
            {{ posts[0].sender.name }}
            <span class="caption grey--text">
              - 2 days ago
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-card-text v-if="showcase === 0">
        <div class="px-2">
          <span class="text-body-1">
            Eae galera! Este é o problema do custo do hotel!
          </span>
        </div>

        <div class="my-2"></div>

        <v-expand-transition hide-on-levea>
          <div v-if="!manimSlides">
            <v-textarea
              v-model="manimQuery"
              filled
              :disabled="manimLoading"
              :background-color="textBackgroundColor"
              placeholder="Write your question here"
              class="hs-rounded-text"
            />
            <v-btn
              :loading="manimLoading"
              color="primary"
              large
              style="border-radius: 12px;"
              block
              @click="$emit('activateManim')"
            >
              animate explanation
            </v-btn>
          </div>
        </v-expand-transition>

        <v-expand-transition hide-on-leave>
          <div v-if="manimSlides" class="my-4" style="height: 45vh">
            <ManimSlides  />
          </div>
          
        </v-expand-transition>
      </v-card-text>

      <v-card-text v-if="showcase === 1">
        <div class="px-2">
          <span class="text-body-1">
            Raio-X Fuvest Anglo - Física Prof Harley Sato
          </span>
        </div>

        <div class="my-2"></div>

        <YouTubePlayer
          url="https://www.youtube.com/watch?v=kfnlpIACGN4" 
          thumbnail="https://i.ytimg.com/vi/kfnlpIACGN4/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLB_amlCRSSr4QryKRUfWiK_SX5nLg"
        />

        <RoundedPlayer
          v-if="false"
          style="max-height: 200px;"
          videoId="demo-video"
          url="https://hisolverlab.s3.amazonaws.com/Animations/FibonacciSequence.mp4"
          thumbnail="https://hisolverlab.s3.amazonaws.com/Animations/FibonacciSequence.png"
          :options="{
            controls: true,
            autoplay: false,
            responsive: true,
            playbackRates: [0.5, 1, 1.5, 2] 
          }"
          :isPreview="true"
          :mediaViewer="true"
        />
      </v-card-text>

      <v-card-text v-if="showcase === 2">
        <div class="px-2">
          <span class="text-body-1">
            Raio-X Fuvest Anglo - Química - Prof Rodrigo Machado
          </span>
        </div>

        <div class="my-2"></div>
<!-- 
        <YouTubePlayer
          url="https://www.youtube.com/watch?v=JEs8fomTiv8" 
          thumbnail="https://i.ytimg.com/vi/JEs8fomTiv8/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLCb52ofPepQp3Rq-lAY-NEnc-CZTQ"
        /> -->

        <RoundedPlayer
          style="max-height: 200px;"
          videoId="demo-video"
          url="https://hisolverlab.s3.amazonaws.com/Animations/BUBLE_SORT_ALGORITHM_CONFIGURABLE.mp4"
          thumbnail="https://hisolverlab.s3.amazonaws.com/Animations/BUBLE_SORT_ALGORITHM_CONFIGURABLE.png"
          :options="{
            controls: true,
            autoplay: false,
            responsive: true,
            playbackRates: [0.5, 1, 1.5, 2] 
          }"
          :isPreview="true"
          :mediaViewer="true"
        />
      </v-card-text>

      <v-card-text v-if="showcase === 3">
        <div class="px-2">
          <span class="text-body-1">
            Turmas de agosto de 2024, ainda dá tempo de se
          </span>
        </div>
<!-- 
        <YouTubePlayer
          url="https://www.youtube.com/watch?v=K9bs5gRafbQ" 
          thumbnail="https://i.ytimg.com/vi/K9bs5gRafbQ/hqdefault.jpg?sqp=-oaymwE9CNACELwBSFryq4qpAy8IARUAAAAAGAElAADIQj0AgKJDeAHwAQH4Af4JgALQBYoCDAgAEAEYZSBYKEwwDw==&rs=AOn4CLDgoYKgHq1VCfsrbaeLZN_k6TDv1g"
        /> -->

        <div class="my-2"></div>

        <RoundedPlayer
          style="max-height: 200px;"
          videoId="demo-video"
          url="https://hisolverlab.s3.amazonaws.com/Animations/PARAMETRIC_FUNCTIONS_WITH_TRACKER.mp4"
          thumbnail="https://hisolverlab.s3.amazonaws.com/Animations/PARAMETRIC_FUNCTIONS_WITH_TRACKER.png"
          :options="{
            controls: true,
            autoplay: false,
            responsive: true,
            playbackRates: [0.5, 1, 1.5, 2] 
          }"
          :isPreview="true"
          :mediaViewer="true"
        />
      </v-card-text>      
    </v-container>
  </v-card>
</template>

<script>
import { RoundedPlayer } from '@components/Video';
import { YouTubePlayer } from '@components/Video';
import ManimSlides from '@components/Post/ManimSlides.vue'

export default {
  components: {
    YouTubePlayer,
    RoundedPlayer,
    ManimSlides
  },

  props: {
    showcase: {
      type: Number,
      default: 0
    },
    posts: {
      type: Array,
      default() {
        return []
      }
    },
    manimSlides: {
      type: Boolean,
      default: false
    },
    manimLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>