<template>
  <v-sheet
    v-ripple="!edit"
    :color="cardColor"
    :outlined="!$vuetify.theme.dark"
    class="clickable hs-rounded-lg"
    @click="goToConvo"
  >
    <v-fade-transition hide-on-leave>
      <v-hover
        v-if="!edit"
        v-slot:default="{ hover }"
      >
        <v-list-item>
          <v-list-item-avatar
            class="hs-rounded-lg"
            size="40"
          > 
            <v-img :src="img[index]">
              <template v-slot:placeholder>
                <v-skeleton-loader
                  class="mx-auto"
                  height="40"
                  width="40"
                  type="image"
                />
              </template>
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ name[index] }}
            </v-list-item-title>
          </v-list-item-content>

          <v-fade-transition hide-on-leave>
            <v-list-item-icon
              v-if="hover"
              class="my-0"
              style="padding: 14px 0px 14px 0px;"
            >  
              <v-btn
                icon
                small
              >
                <v-icon small>
                  {{ mdiStarFourPointsOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                small
              >
                <v-icon small>
                  {{ mdiBookmarkOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                small
                @click.stop="edit = !edit"
              >
                <v-icon small>
                  {{ mdiPencil }}
                </v-icon>
              </v-btn>

              <v-icon v-if="false">
                {{ mdiSeal }}
              </v-icon>
            </v-list-item-icon>
          </v-fade-transition>
        </v-list-item>
      </v-hover>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-list-item v-if="edit">
        <v-list-item-content class="py-2">
          <v-text-field
            v-model="room.name"
            class="hs-rounded-lg"
            placeholder="pdf title"
            :color="inputColor"
            hide-details
            dense
            elevation="0"
            filled
          />
        </v-list-item-content>

        <v-list-item-icon class="pl-2" style="margin: 13px 0px 13px 0px;">
          <v-btn
            icon
            small
            @click.stop="update"
          >
            <v-icon small>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-fade-transition>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@api'

import {
  mdiAccountCircle,
  mdiSeal,
  mdiStarFourPointsOutline,
  mdiBookmarkOutline,
  mdiPencil,
  mdiCheck
} from '@mdi/js'

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    room: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  
  data() {
    return {
      mdiAccountCircle,
      mdiSeal,
      mdiStarFourPointsOutline,
      mdiBookmarkOutline,
      mdiPencil,
      mdiCheck,

      edit: false,

      img: [
        'https://image.lexica.art/full_webp/c09850b0-5850-4cfe-b722-abbddae1642d',
        'https://image.lexica.art/full_webp/05b9c8b9-6b37-43b8-af80-82d73ddba91b',
        'https://image.lexica.art/full_webp/c4993b09-68e5-4909-9fa6-b78fb93c8fda',
        'https://image.lexica.art/full_webp/2fed3a5a-4c4c-4144-bf6f-cc92308bcfa5',
        'https://image.lexica.art/full_webp/e284dadb-96ee-4f7c-a980-e2799d9778c1',
        'https://image.lexica.art/full_webp/7a3db6f9-f284-44e5-8695-3a7522b861de',
        'https://image.lexica.art/full_webp/37da5a2b-a4af-4a61-a337-f8dcb4ebd337',
        'https://image.lexica.art/full_webp/9c732a24-f3e3-430d-806d-31f429585320',
        'https://image.lexica.art/full_webp/ae070b35-d9a5-4a28-b3ff-44a5da302953',
        'https://image.lexica.art/full_webp/4ecd4d4a-c2df-4e6a-bc48-86457914d5d5',
        'https://img.lexica.art/11ba3955-1ef6-4926-916f-13d8affabecf_full.webp'
      ],

      name: [
        'Maths of human enhancement',
        'Math art',
        'Ancient mathematics',
        'Brain computer intefaces',
        'Storytelling in STEM',
        'Math in music',
        'The big problems',
        'How to use Anna',
        'Annatar and personas',
        'How to use HiSolver',
        'It\'s all about community'
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),

    cardColor() {
      return this.$vuetify.theme.dark ? '#262626' : ''
    },

    inputColor() {
      return this.$vuetify.theme.dark ? 'white' : ''
    }
  },

  methods: {
    async goToConvo() {
      this.$router.push(`/rooms/conversation?id=64990d29f2bf4700122ec507`)
    },

    update() {
      API().put(`room/${this.user.id}`, {
        name: this.room.name
      }, {
        params: {
          id: this.room._id
        }
      })

      this.edit = false
    },

    emitRoom() {
      if (!this.edit) this.$emit('setCurrentRoom')
    },

    emitNewSenderRequest(data) {
      this.$emit('newSenderRequest', data)
    },

    emitSnack(snackMsg) {
      this.$emit('snack', snackMsg)
    }
  }
}
</script>