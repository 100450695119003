<template>
  <v-container
    ref="textContainer"
    class="py-1"
  >
    <v-dialog
      v-model="mentionCardOn"
      hide-overlay
      max-width="0"
      @click:outside="mentionCardOn = false"
    >
      <MentionCard
        :mentionCardOn="mentionCardOn"
        :mentionCardStyle="mentionCardStyle"
        :upperHalfOn="mentionCardUpperHalfOn"
        @hideMentionCard="mentionCardOn = false"
        @doMention="doMention"
      />
    </v-dialog>

    <v-row
      justify="center"
      align="start"
    >
      <v-col
        cols="12"
      >
        <TextInput
          :style="`margin-bottom: 2px; width: ${textInputWidth}%;`"
          :index="0"
          :label="label"
          :content="text"
          :latexOn="true"
          :activeFocusOn="focusOn"
          :focusOn="true"
          :syncContentOn="syncContentOn"
          :syncSwitch="syncSwitch"
          :syncCarriageReturn="syncCarriageReturn"
          :syncCarriageStart="syncCarriageStart"
          :syncCarriageEnd="syncCarriageEnd"
          :syncCarriageUp="syncCarriageUp"
          :syncCarriageDown="syncCarriageDown"
          :previousCaretLeft="previousCaretLeft"
          :syncEmoji="syncEmoji"
          :syncLink="syncLink"
          :syncRangeContainer="syncRangeContainer"
          :syncRangeOffset="syncRangeOffset"
          :mentionCardOn="mentionCardOn"
          :syncMention="syncMention"
          :mentionData="mentionData"
          @mention="setMentionCard"
          @focus="focusOn = true"
          @blur="focusOn = false"
          @textInput="setTextContent"
          @overrideSyncContent="overrideSyncContent"
          @setRangeState="setRangeState"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { MentionCard } from '@components'
import TextInput from './TextInput'
import { uniq } from 'lodash'

import {
  mdiEmoticonExcitedOutline,
  mdiAccount,
  mdiAttachment
} from '@mdi/js'

export default {

  components: {
    TextInput,
    MentionCard
  },

  props: {
    baseText: {
      type: String,
      default: ''
    },
    syncSubmit: {
      type: Boolean,
      default: false
    }
  },

  created() {
    if (this.baseText) {
      this.text = this.baseText
    }
  },

  data() {
    return {
      mdiAccount,
      mdiAttachment,
      mdiEmoticonExcitedOutline,

      mentionCardUpperHalfOn: true,

      mentionCardOn: false,
      mentionCardLeft: null,
      mentionCardTop: null,
      syncMention: false,
      mentionData: {},
      mentions: [],

      contentMentions: [],

      text: '',

      emojiPickerOn: false,
      emojiPickerTop: 0,
      emojiPickerLeft: 0,

      focusOn: false,

      syncContentOn: false,
      syncSwitch: false,
      syncCarriageReturn: false,
      syncCarriageStart: false,
      syncCarriageEnd: false,
      syncCarriageUp: false,
      syncCarriageDown: false,
      previousCaretLeft: null,
      syncEmoji: false,
      syncLink: false,
      syncRangeContainer: {},
      syncRangeOffset: 0
    }
  },

  watch: {
    syncSubmit(sync) {
      if (sync) {
        this.$emit('text', this.text)
      }
    }
  },

  computed: {

    ...mapGetters({
      profileName: 'profile/name',
      profileAvatar: 'profile/avatar',
      scrollTop: 'scrollTop'
    }),

    textContainerClass() {
      
      const { xl, width } = this.$vuetify.breakpoint

      return xl || width < 1300
        ? 'px-3'
        : 'pl-3'
        
    },

    colorClass() {
      return this.$vuetify.theme.dark
        ? 'viewer-dark'
        : this.lighterViewerOn
        ? 'viewer-lighter'
        : 'viewer-light'
    },

    textInputWidth() {
      return this.$vuetify.breakpoint.xl ? 92 : 86
    },

    mentionCardStyle() {
      return `position: absolute;
        z-index: 100;
        left: ${this.mentionCardLeft}px;
        top: ${this.mentionCardTop}px;`
    },

    avatarClass() {
      return this.$vuetify.breakpoint.xl ? 'ml-2' : 'mr-4'
    },

    avatarSize() {
      return this.profileAvatar ? '40px' : ''
    },

    emojiPickerLightOn() {
      return this.emojiPickerOn && !this.$vuetify.theme.dark
    },

    emojiPickerDarkOn() {
      return this.emojiPickerOn && this.$vuetify.theme.dark
    }

  },

  methods: {

    submit() {

      const mentionEls = this.$refs.textContainer.querySelectorAll('a')
      const mentionProfiles = []

      for (let el of mentionEls) {

        const { profile } = el.dataset

        if (mentionProfiles.indexOf(profile) === -1) {
          mentionProfiles.unshift(profile)
        }

      }

      const mentions = uniq(this.mentions.filter(({id}) => {
        return mentionProfiles.indexOf(id) != -1
      }))
      .map(profileData => {
        return {
          profile: profileData.id,

          ...profileData
        }
      })

      const contentMentions = {
        comments: this.contentMentions.map(({ id }) => id)
      }

      this.$emit('submit', {
        paragraph: this.text,
        contentMentions,
        mentions
      })

      this.syncContentOn = true
      this.text = ''
      
      setTimeout(() => {
        this.syncContentOn = false
      })
    
    },

    setTextContent({ textContent }) {
      this.text = textContent
    },

    doMention({ username, avatar, bio, name, id }) {

      this.mentions.unshift({ username, name, avatar, bio, id })

      this.mentionData = {
        username,
        id
      }
      
      this.syncMention = true

      setTimeout(() => {
        this.mentionCardOn = false
        this.syncMention = false
      })

    },

    setMentionCard({ x }) {

      const { textContainer } = this.$refs
      const { y } = textContainer.getBoundingClientRect()

      this.mentionCardLeft = x
      this.mentionCardUpperHalfOn = y < window.innerHeight / 2
      this.mentionCardTop = !this.mentionCardUpperHalfOn ? y - 400 : y
      
      this.mentionCardOn = true

    }

  }
}
</script>

<style scoped>
.viewer-dark {
 border: thin solid rgba(255, 255, 255, 0.12);
 background-color: rgb(39, 39, 39);
}

.viewer-light {
 border: thin solid rgba(0, 0, 0, 0.12);
 background-color: rgba(202, 199, 199, 0.12);
}

.viewer-lighter {
  border: thin solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
}
</style>