<template>
  <v-container>
    <RoundedPlayer
      :videoId="uniqVideoId"
      :url="videoSrc"
      :thumbnail="thumbnail"
      :isPreview="isPreview"
      :syncTheaterMode="false"
      @theaterMode="toggleTheater"
    />
  </v-container>
</template>

<script>
import { RoundedPlayer } from '@components/Video'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    RoundedPlayer
  },
  
  props: {
    videoId: {
      type: String,
      default: 'solo'
    },
    videoSrc: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: true
    }
  },

  created() {
    this.uniqVideoId = `${this.videoId}-${uuidv4()}`
  },

  data() {
    return {
      duration: '',
      uniqVideoId: null
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    toggleTheater(theaterModeOn) {
      this.$emit('theaterMode', theaterModeOn)
    }
  }
}
</script>