<template>
  <div>
    <MediaViewer
      :dialogOn="dialogOn"
      :post="post"
      :selectedMedia="selectedMedia"
      @close="dialogOn = false"
    />

    <markdown-it-vue
      class="md-body"
      :style="`${ dense ? 'max-height: 280px !important;' : '' } overflow: hidden; text-overflow: ellipsis;`"
      :content="content"
      :options="options"
    />
    <div v-if="dense" class="divider-container">
      <div class="card-body" ></div>
    </div>
  </div>
</template>

<script>
import ImageGallery from './ImageGallery'
import PostVideo from './PostVideo'
import MediaViewer from './MediaViewer'
import { FileBox } from '@components/File'
import { AudioPlayer } from '@components/Audio'
import { YouTubePlayer } from '@components/Video'
import { Scrollbar } from '@components/App'
import { RoundedPlayer } from '@components/Video';
import { htmlToMarkdown } from '@utils';

import MarkdownItVue from 'markdown-it-vue/src/index.js'
import 'markdown-it-vue/dist/markdown-it-vue.css'

import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiShimmer
} from '@mdi/js'

export default {
  components: {
    RoundedPlayer,
    Scrollbar,
    ImageGallery,
    PostVideo,
    AudioPlayer,
    FileBox,
    YouTubePlayer,
    MediaViewer,
    MarkdownItVue
  },

  props: {
    postStr: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    this.setPost(this.post)
  },

  watch: {
    post(postObj) {
      this.setPost(postObj)
    }
  },

  data() {
    return {
      mdiChevronRight,
      mdiChevronLeft,
      mdiClose,
      mdiShimmer,

      options: {
        markdownIt: {
          linkify: true
        },
        katex: {
          throwOnError: false,
          errorColor: '#cc0000'
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener'
          }
        }
      },

      showAnswer: false,

      dialogOn: false,
      selectedMedia: 'image',
      contentCardOn: false,

      audioIdx: 0,
      videoIdx: 0,

      audioSrc: '',
      videoSrc: '',
      thumbnailSrc: '',

      content: '',

      files: []
    }
  },

  computed: {
    post() {
      return JSON.parse(this.postStr)
    },

    videoMargin() {
      return this.post.mediaContent.videos.length > 1 ? 'my-4' : 'mt-4 mb-8'
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? 'grey darken-4' : ''
    }
  },

  methods: {
    movAudio(forward) {
      this.audioIdx += forward ? 1 : -1
      const { url } = this.post.mediaContent.audios[this.audioIdx]
      this.audioSrc = ''

      setTimeout(() => {
        this.audioSrc = url
      })
    },

    movVideo(forward) {

      this.videoIdx += forward ? 1 : -1
      const { url, thumbnail } = this.post.mediaContent.videos[this.videoIdx]
      this.videoSrc = ''
      this.thumbnailSrc = thumbnail

      setTimeout(() => {
        this.videoSrc = url     
      })

    },

    setTheater() {
      this.selectedMedia = 'video'
      this.dialogOn = !this.dialogOn
    },

    setImgViewer() {
      this.selectedMedia = 'image'
      this.dialogOn = !this.dialogOn
    },

    setPost(postObj) {
      //const { audios, videos, files } = postObj.mediaContent
      // const { libraryTags } = postObj

      // this.audioIdx = 0
      
      // if (audios.length > 0) {
      //   const [{ url }] = audios
      //   this.audioSrc = url
      // } else {
      //   this.audioSrc = ''
      // }

      // if (files.length > 0) {
      //   this.setFiles(files)
      // } else {
      //   this.files = []
      // }
      
      // this.videoIdx = 0

      // if (videos.length > 0) {
      //   const [{ url, thumbnail }] = videos
      //   this.videoSrc = ''
      //   this.thumbnailSrc = ''

      //   setTimeout(() => {
      //     this.videoSrc = url
      //     this.thumbnailSrc = thumbnail
      //   })
      // } else {
      //   this.videoSrc = ''
      //   this.thumbnailSrc = ''
      // }

      // Object.keys(libraryTags).forEach(key => {
      //   this.libraryTags[key] = libraryTags[key]
      // })

      const textBlocks = postObj.content.blocks
        .filter(block => block.type === 'paragraph' || block.type === 'header')
        .map(block => block.data.text);

      this.content = htmlToMarkdown(textBlocks.join('\n'));
    },

    async setFiles(files) {
      const filePromises = files.map(({url}) => fetch(url).then(({body}) => body))
      const readableStreams = await Promise.all(filePromises)
      
      const fileBlobs = this.files

      readableStreams.forEach((readableStream, i) => {
        const reader = readableStream.getReader();
        let fileChunks = '';
        let file;

        reader.read().then(function processText({done, value}) {

          if (done) {
            const uint8Array = new Uint8Array(fileChunks.split(',').map(Number));
            const { filename, contentType } = files[i];

            file = new File([uint8Array], filename, { type: contentType });
            fileBlobs.push(file);
            
            return;
          }

          fileChunks += value;

          return reader.read().then(processText);

        })
        
      })
    }

  }
  
}
</script>

<style scoped>
.divider-container {
  position: relative;
}

.theme--light .card-body::after {
  content: "";
  position: absolute;
  bottom: 13.5%;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust the height to control the fade effect */
  background: linear-gradient(to bottom, transparent, rgb(255, 255, 255)); /* Adjust the color to match your background */
}

.theme--dark .card-body::after {
  content: "";
  position: absolute;
  bottom: 13.5%;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust the height to control the fade effect */
  background: linear-gradient(to bottom, transparent, rgb(31, 31, 31)); /* Adjust the color to match your background */
}

.small-inset {
  margin-left: 90px;
}
</style>