<template>
  <v-container>
    <v-row
      justify="start"
      class="px-4"
    >
      <v-list-item-avatar
        size="45"
        color="grey"
      >
        <v-img :src="comment.sender.avatar" />
      </v-list-item-avatar>

      <v-list-item-content>
        <div class="overline">
          {{ comment.sender.name }}   
          <span class="caption grey--text">
            - {{ createdAt }}
          </span>
        </div>
      </v-list-item-content>

      <v-list-item-action>
        <v-fade-transition hide-on-leave>
          <v-row v-if="!edit">
            <v-tooltip
              v-if="comment.sender.profile === myProfile"
              :disabled="!settings.tooltipsOn"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  icon
                  @click="edit = true"
                >
                  <v-icon>
                    {{ mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>editar</span>
            </v-tooltip>
            
            <v-btn
              icon
              @click="$emit('reply', comment)"
            >
              <v-icon>
                {{ mdiReplyOutline }}
              </v-icon>
            </v-btn>
            
            <v-btn
              icon
              @click="doLikeContent"
            >
              <v-icon :color="likeContent ? 'red darken-4' : ''">
                {{ likeContent ? mdiHeart : mdiHeartOutline }}
              </v-icon>
            </v-btn>
          </v-row>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <v-row v-if="edit">
            <v-btn
              icon
              small
              outlined
              class="mx-2"
              color="success"
              @click="syncEdit = true"
            >
              <v-icon>{{ mdiCheck }}</v-icon>
            </v-btn>

            <v-btn
              icon
              outlined
              small
              color="danger"
              @click="edit = false"
            >
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-row>
        </v-fade-transition>
      
      </v-list-item-action>       
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-fade-transition hide-on-leave>
          <span
            v-if="!edit"
            v-katex:auto="{ options: latexOptions }"
            v-html="formatComment(comment.paragraphs)"
          />
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <TextField
            v-if="edit"
            :baseText="comment.paragraphs[0]"
            :syncSubmit="syncEdit"
            @text="updateComment"
          />
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TextField from '@components/Document/TextField'
import { formatDate, latexOptions, truncateStr } from '@utils'
import API from '@api'

import {
  mdiDotsHorizontal,
  mdiClose,
  mdiCheck,
  mdiDelete,
  mdiHeart,
  mdiHeartOutline,
  mdiReplyOutline,
  mdiPencilOutline
} from '@mdi/js'

export default {

  components: {
    TextField
  },

  props: {
    commentId: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    commentStr: {
      type: String,
      required: true
    }
  },

  created() {
    this.setEngagement()
  },

  data() {
    return {
      mdiDotsHorizontal,
      mdiDelete,
      mdiCheck,
      mdiClose,
      mdiHeart,
      mdiHeartOutline,
      mdiReplyOutline,
      mdiPencilOutline,

      latexOptions,

      syncEdit: false,
      edit: false,

      likeContent: false

    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      settings: 'settings',
      scrollTop: 'scrollTop',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar'
    }),

    comment() {
      return JSON.parse(this.commentStr)
    },

    createdAt() {
      return formatDate(this.comment.createdAt, this.$i18n.locale)
    }

  },

  watch: {
    commentId() {
      this.setEngagement()
    }
  },

  methods: {

    async updateComment(text) {

      await API().put(`comment/${this.user.id}`, {
        paragraphs: [text]
      },
      {
        params: {
          id: this.comment.id
        }
      })

      this.$emit('updateComment', { text, index: this.index })

      setTimeout(() => {
        this.edit = false
        this.syncEdit = false
      })

    },

    truncate(str = '', num = 200) {

      return truncateStr(str, num)

    },

    formatComment([paragraph]) {
      return paragraph.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    async doLikeContent() {
      
      await API().put(`engagement/${this.user.id}`, {
        likeContent: !this.likeContent
      },
      {
        params: {
          query: {
            comment: {
              $eq: this.comment.id
            },
            profile: {
              $eq: this.myProfile
            }
          },
          options: {
            upsert: true
          }
        }
      })

      this.likeContent = !this.likeContent
      
    },

    async setEngagement() {

      const [engagement] = await API().get(`engagement/${this.user.id}`, {
        params: {
          comment: this.comment.id,
          profile: this.myProfile
        }
      })

      if (engagement) {

        this.likeContent = engagement.likeContent

      }

    }
  }
}
</script>