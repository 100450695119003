<template>
  <div class="px-12">
    <PageLayout
      :tab="tab"
      :setTab="setTab"
      :_community="community"
    >
      <template v-slot:child="pageProps">
        <v-row v-if="tab === 0">
              <v-col cols="8">
                <div>
                  <NewPost
                    :newPostDialogOn="newPost"
                    :currentRoom="currentRoom.id"
                    @newPost="addPost"
                    @close="newPost = false"
                  />

                  <Header
                    :currentPost="currentPost"
                    :room="currentRoom"
                    :tab="postTab"
                    @setActive="setPostTab"
                    @post="newPost = !newPost"
                    @reset="resetPost"
                  ></Header>

                  <div class="my-4"></div>

                  <v-fade-transition hide-on-leave>
                    <div v-if="postTab === 0">
                      <ContentPost
                        :manimSlides="manimSlides"
                        :posts="posts"
                        :manimLoading="manimLoading"
                        @activateManim="activateManim"
                      />

                      <div class="my-4"></div>

                      <ContentPost
                        :showcase="1"
                        :manimSlides="manimSlides"
                        :posts="posts"
                        :manimLoading="manimLoading"
                        @activateManim="activateManim"
                      />

                      <div class="my-4"></div>

                      <ContentPost
                        :showcase="2"
                        :manimSlides="manimSlides"
                        :posts="posts"
                        :manimLoading="manimLoading"
                        @activateManim="activateManim"
                      />
                      
                      <div class="my-3"></div>

                      <ContentPost
                        :showcase="3"
                        :manimSlides="manimSlides"
                        :posts="posts"
                        :manimLoading="manimLoading"
                        @activateManim="activateManim"
                      />
                    </div>
                  </v-fade-transition>

                  <v-fade-transition hide-on-leave>
                    <div v-if="postTab === 1">
                      <v-fade-transition hide-on-leave>
                        <div v-if="!currentPost">
                          <div
                            v-for="(post, i) in posts.filter(({ type }) => type === 'thread')"
                            :key="`post_${i}`"
                          >
                            <div class="my-4"></div>

                            <PostCard
                              :objectId="post.id"
                              :post="post"
                              :community="community"
                              :dense="true"
                              @open="openPost(i)"
                            />    
                          </div>
                        </div>
                      </v-fade-transition>

                      <v-fade-transition hide-on-leave>
                        <div v-if="currentPost">
                          <div class="my-4"></div>

                          <Thread
                            :currentPost="currentPost"
                            :community="community"
                          />

                          <div style="height: 30vh"></div>
                        </div>
                      </v-fade-transition>
                    </div>
                  </v-fade-transition>

                  <v-fade-transition hide-on-leave>
                    <div v-if="postTab === 2">
                      <ChatCard
                        v-for="i in 10"
                        :key="`chat_${i}`"
                        :index="i"
                        class="mb-4"
                      />
                    </div>
                  </v-fade-transition>
                </div>
              </v-col>
              <v-col
                cols="4"
              >
                <PersonaPanel style="position: sticky; top: 30px;" />
              </v-col>
            </v-row>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from '@components/Hall/PageLayout'
import { mapGetters } from 'vuex'
import PersonaPanel from '@components/Persona/PersonaPanel'
import NewPost from '@components/Post/NewPost'
import { PostCard, ContentPost, CommentBox } from '@components/Post'
import Header from '@components/Hall/Header'
import Thread from '@components/Post/Thread'
import ChatCard from '@components/Conversation/ChatRoomCard'
import API from '@api'

export default {
  components: {
    Header,
    PostCard,
    ContentPost,
    PersonaPanel,
    CommentBox,
    NewPost,
    Thread,
    ChatCard,
    PageLayout
  },
  
  async created() {
    if (this.$route.query.id) {
      this.currentRoom = await API().get(`room/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })

      if (this.currentRoom.community) {
        const posts = await API().get(`posts/${this.user.id}`, {
          params: {
            query: {
              room: {
                $eq: this.currentRoom.id
              },
              isActive: {
                $eq: true
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })
        this.posts = [...this.posts, ...posts]
      
        this.community = await API().get(`community/${this.user.id}`, {
          params: {
            id: this.currentRoom.community
          }
        })
      }
    }
  },

  data() {
    return {
      currentPost: null,
      newPost: false,
      manimLoading: false,
      manimSlides: false,
      manimQuery: '',
      postTab: 0,
      posts: [{
        _id: '6485ee38c1918500c2f76190',
        sender: {
          _id: '6485ee38c1918500c2f76191',
          name: 'Mark Trevelian',
          profile: '6472e4b12e5ae7002b3dcc4a',
          avatar: `https://image.lexica.art/full_webp/342e4bda-0ab1-4ee4-b50e-78ba9ed72456`
        }
      }],
      currentRoom: {},
      tab: 0,
      fabOptions: false,
      editHall: false,
      editContent: false,
      admin: false,
      loading: true,
      inviteOn: false,
      newRoomOn: false,
      checkoutOn: false,
      uploadOn: false,
      tabs: 0,
      subscription: null,
      showMore: false,
      contentBundles: [],
      contents: [],
      members: [],
      community: {},
      member: {},
      notes: []
    }
  },

  computed: {
    ...mapGetters({
      anna: 'anna',
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),
  },

  methods: {
    resetPost() {
      this.currentPost = null
    },

    openPost(i) {
      this.currentPost = this.posts.filter(({ type }) => type === 'thread')[i]
    },

    addPost(post) {
      this.posts.unshift(post)
    },

    setTab(tab) {
      this.tab = tab
    },

    setPostTab(tab) {
      this.postTab = tab
    },

    activateManim() {
      this.manimLoading = true
      
      setTimeout(() => {
        this.manimSlides = true
        this.manimQuery = ''
        this.manimLoading = false
      }, 1000)
    },

    addBundle(bundleObj) {
      this.contentBundles.push(bundleObj)
      this.uploadOn = false
    },

    bundleRoute({ id }) {
      return id ? `/bundle?id=${id}` : ''
    },

    addRoom(room) {
      this.rooms.push(room)
      this.newRoomOn = false
    },

    updateRoom(room) {
      this.currentRoom = room
      this.newRoomOn = false
    }
  }
}
</script>